import React from "react";

import ScrollAnimation from "../Animation/Scroll_Animation";
import EmailForm from "../Email_Form/Email_Form";

import styles from "./Contact.module.css"
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const Contact = () => {
  const header = () => {
    return(
      <div className={styles.imageContainer}>
        <div>
          <ScrollAnimation 
            widget={
              <div className={styles.titleOverlay}>
                <h2 className={`${styles.mainTitle} px-3 pt-2 pb-3`}>Contact Us</h2>
              </div>
            }
            direction={'fade-up'}
          />
        </div>
      </div>
    );
  }

  const leadingQuestions = () => {
    return (
      <ScrollAnimation widget={
        <div className={styles.questionContainer}>
          <Stack className={styles.questions}>
            <div className={styles.subtitle}>
              Have questions? 
            </div>
            <div className={styles.subtitle}>
              Ready to start your journey with us? 
            </div>
            <div className={styles.subtitle}>
              Want to hear more about our financing options? 
            </div>
            <div className={styles.subtitle}>
              Curious about curriculum details? 
            </div>
          </Stack>
          
        </div>
        
      } direction={'fade-down'} />
    );
  }

  return (
    <div className={styles.body}>
      {header()}
      <Container className={styles.custContainer}>
        <Row className="py-5 d-flex justify-content-center align-items-start">
          <Col xs={12} md={6} className="d-none d-lg-flex justify-content-start">
           {leadingQuestions()}
            
          </Col>
          <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
            <div className={styles.emailOverlap}>
              <ScrollAnimation 
                widget={<EmailForm />}
                direction={'fade-up'}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    
  );
}

export default Contact;

